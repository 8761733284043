import React, { useState, useEffect } from "react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useLocation, useParams, Link as RouterLink } from "react-router-dom";
import { getRequestInstanceTwo } from "../../apiServices/apiCall";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Breadcrumbs,
  Link as MuiLink,
  Box,
  styled,
  TextField,
  Button,
  Autocomplete,
  Grid,
  TableFooter,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import * as XLSX from "xlsx";

const StyledTableCell = styled(TableCell)(({ theme, isHeader, isFooter }) => ({
  backgroundColor: isHeader ? "#d3d4ee" : isFooter ? "#f5f5f5" : "transparent",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: "center",
  position: isHeader || isFooter ? "sticky" : "relative",
  top: isHeader ? 0 : "auto",
  bottom: isFooter ? 0 : "auto",
  zIndex: isHeader || isFooter ? 1 : "auto",
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
  padding: "8px 12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "6px 10px",
  },
}));

const TableWrapper = styled(Box)({
  width: "100%",
  overflowX: "auto",
});

const useStyles = makeStyles(() => ({
  search: {
    margin: "0",
  },
  clearIcon: {
    cursor: "pointer",
  },
}));

const SalesOfferByOutletIdDetail = () => {
  const { offer_name, id, salesgroup, payerId, outledId } = useParams();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const time_frame = query.get("time-frame");
  const startDate = query.get("start_date");
  const endDate = query.get("end_date");

  const [salesDetails, setSalesDetails] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(
    time_frame || `${startDate} to ${endDate}`
  );
  const [exportSalesDetails, setExportSalesDetails] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoadingGift, setIsLoadingGift] = useState(false);
  const [exportGiftDetails, setExportGiftDetails] = useState([]);
  const [customDateRange, setCustomDateRange] = useState({
    start_date: startDate,
    end_date: endDate,
  });
  const [pageLoad, setPageLoad] = useState(true);

  const [searchValue, setSearchValue] = useState("");
  const [showClearIcon, setShowClearIcon] = useState("none");

  const { search, clearIcon } = useStyles();

  const fetchData = async (start_date, end_date) => {
    // console.log(id);
    setIsLoadingTable(true);
    try {
      const params = new URLSearchParams();
      params.append("start_date", start_date);
      params.append("end_date", end_date);
      params.append("offer_id", id);
      params.append("salesgroup_name", salesgroup);
      params.append("payer_id", payerId);
      params.append("outletId_id", outledId);

      const url = `/api/outleId_details?${params.toString()}`;
      const response = await getRequestInstanceTwo(url);
      // console.log("Sales Details:", response);

      const uniqueSalesDetails = Array.from(
        response.result.map(JSON.stringify)
      ).map(JSON.parse);

      setSalesDetails(uniqueSalesDetails);
      setExportSalesDetails(uniqueSalesDetails);
    } catch (error) {
      console.error("Error fetching sales details:", error);
    } finally {
      setIsLoadingTable(false);
    }
  };

  const fetchGiftData = async (start_date, end_date) => {
    setIsLoadingGift(true);
    try {
      const params = new URLSearchParams();
      params.append("start_date", start_date);
      params.append("end_date", end_date);
      params.append("offer_id", id);
      params.append("payer_id", payerId);
      params.append("outletId_id", outledId);
      params.append("salesgroup_name", salesgroup);
      const url = `/api/gift_count?${params.toString()}`;

      const response = await getRequestInstanceTwo(url);

      // Ensure uniqueness in your data if needed
      const uniqueSalesDetails = Array.from(
        response.result.map(JSON.stringify)
      ).map(JSON.parse);

      setExportGiftDetails(uniqueSalesDetails);
    } catch (e) {
      console.error("Error fetching sales details:", e);
      // Consider adding UI feedback for the user
    } finally {
      setIsLoadingGift(false);
    }
  };

  useEffect(() => {
    if (selectedFilter !== null) {
      handleFilterSelect(selectedFilter);
    } else if (startDate !== null) {
      handleFilterSelect("Custom Range");
    }
  }, [selectedFilter]);

  const handleDateChange = (field) => (event) => {
    setCustomDateRange({
      ...customDateRange,
      [field]: event.target.value,
    });
  };
  /// change

  const handleCustomRangeSubmit = () => {
    const { start_date, end_date } = customDateRange;
    let searchParams = new URL(window.location);
    searchParams.searchParams.delete("time-frame");
    searchParams.searchParams.set("start_date", start_date);
    searchParams.searchParams.set("end_date", end_date);
    window.history.replaceState(null, "", searchParams);
    // setSelectedFilter("Custom Range");
    setOpenDialog(false);
    setSelectedFilter( start_date + " to " + end_date );

    fetchData(start_date, end_date);
    fetchGiftData(start_date, end_date);
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    const today = new Date();
    let start_date, end_date;

    switch (filter) {
      case "Today":
        start_date = end_date = today.toISOString().split("T")[0];
        break;
      case "Weekly":
        start_date = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Monthly":
        start_date = new Date(today.setMonth(today.getMonth() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Yearly":
        start_date = new Date(today.setFullYear(today.getFullYear() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Custom Range":
        fetchData(customDateRange.start_date, customDateRange.end_date);
        fetchGiftData(customDateRange.start_date, customDateRange.end_date);
        return;
      default:
        fetchData(customDateRange.start_date, customDateRange.end_date);
        fetchGiftData(customDateRange.start_date, customDateRange.end_date);
        return;
    }

    setCustomDateRange({
      start_date,
      end_date,
    });

    fetchData(start_date, end_date);
    fetchGiftData(start_date, end_date);
  };

  const updateAutoComplete = (filter) => {
    if (filter !== null) {
      if (filter != "Custom Range") {
        setSelectedFilter(filter);
        const searchParams = new URL(window.location);
        searchParams.searchParams.set("time-frame", filter);
        searchParams.searchParams.delete("start_date");
        searchParams.searchParams.delete("end_date");
        window.history.replaceState(null, "", searchParams);
      } else {
        setOpenDialog(true);
      }
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedFilter("");
  };

  const couponNoExists = salesDetails.some((detail) => detail.coupon_no);

  // Aggregate sales details by outletId, coupon_type, beatname, and stokist_name
  const aggregatedSalesDetails = salesDetails.reduce((acc, detail) => {
    const key = detail.outletId;
    if (!acc[key]) {
      acc[key] = {
        gift_name: detail.gift_name,
        coupon_no: detail.coupon_no,
        outletId: detail.outletId,
        coupon_types: {},
      };
    }
    if (!acc[key].coupon_types[detail.coupon_type]) {
      acc[key].coupon_types[detail.coupon_type] = 0;
    }
    acc[key].coupon_types[detail.coupon_type] += detail.scheme_count;
    return acc;
  }, {});

  const uniqueCouponTypes = [
    ...new Set(salesDetails.map((detail) => detail.coupon_type)),
  ];

  // Calculate overall total count
  const overallTotalCount = Object.values(aggregatedSalesDetails).reduce(
    (sum, detail) =>
      sum +
      Object.values(detail.coupon_types).reduce(
        (innerSum, count) => innerSum + count,
        0
      ),
    0
  );

  const handleClearSearch = () => {
    setSearchValue("");
    setShowClearIcon("none");
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
  };

  const normalizeSearchValue = (value) => {
    return value.trim().replace(/\s+/g, " ").toLowerCase();
  };

  // Filter sales details based on outletId, beatname and stokist_name search values
  const filteredSalesDetails = salesDetails.filter((detail) => {
    const outletId = detail.outletId
      ? detail.outletId.toString().toLowerCase()
      : "";
    const gift_name = detail.gift_name ? detail.gift_name.toLowerCase() : "";

    const normalizedSearchValue = normalizeSearchValue(searchValue);

    const outletIdMatches = outletId.includes(normalizedSearchValue);
    const giftNameMatches = gift_name.includes(normalizedSearchValue);

    return outletIdMatches || giftNameMatches;
  });

  console.log("Filtered Sales Details:", filteredSalesDetails);

  const exportExcel = () => {
    const exportData = filteredSalesDetails.map((detail) => {
      const row = {
        "Gift Name": detail.gift_name,
      };

      if (couponNoExists) {
        row["Coupon No"] = detail.coupon_no;
      }

      row["Outlet Id"] = detail.outletId;

      if (detail.scheme_id) {
        row["scheme_id"] = detail.scheme_id;
      }
      if (detail.total_gifts) {
        row["total_gifts"] = detail.total_gifts;
      }

      uniqueCouponTypes.forEach((type) => {
        row[type] = detail.coupon_type === type ? 1 : 0;
      });

      row["Total Count"] = 1;

      return row;
    });

    // Add total row at the bottom
    const totalRow = {
      "Gift Name": "Total",
    };

    if (couponNoExists) {
      totalRow["Coupon No"] = "";
    }

    totalRow["Outlet Id"] = "";

    totalRow["scheme_id"] = "";
    totalRow["total_gifts"] = "";

    uniqueCouponTypes.forEach((type) => {
      totalRow[type] = Object.values(aggregatedSalesDetails).reduce(
        (sum, detail) => sum + (detail.coupon_types[type] || 0),
        0
      );
    });

    totalRow["Total Count"] = Object.values(aggregatedSalesDetails).reduce(
      (sum, detail) =>
        sum +
        Object.values(detail.coupon_types).reduce(
          (acc, count) => acc + count,
          0
        ),
      0
    );

    exportData.push(totalRow);

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sales Details");

    exportGiftExcel(wb);

    XLSX.writeFile(wb, "SalesOfferByOutletIdDetail.xlsx");
  };

  const exportGiftExcel = (workbook) => {
    const tableData = exportGiftDetails.map((detail) => {
      console.log("details: " + detail);

      const { gift_name, Count, Coupen_type } = detail;
      let Total = 0;

      if (gift_name.startsWith("Rs.")) {
        // Extract the numerical value from the Rs. format
        const value = parseFloat(gift_name.replace("Rs. ", ""));
        Total = Count * value;
      } else if (gift_name.match(/^\d+ G X \d+$/)) {
        // Extract the multiplier for formats like 100 G X 1, 250 G X 2, etc.
        const multiplier = parseInt(gift_name.split(" X ")[1]);
        Total = Count * multiplier;
      }

      return gift_name.startsWith("Rs.")
        ? {
            Coupen_type: Coupen_type,
            "Gift Name": gift_name,
            Count: Count,
            Total: Total,
          }
        : {
            Coupen_type: Coupen_type,
            "Gift Name": gift_name,
            Count: Count,
            Total: Total,
          };
    });

    // Create worksheet from the tableData array with defined headers
    const ws = XLSX.utils.json_to_sheet(tableData);

    // Append the worksheet to the provided workbook
    XLSX.utils.book_append_sheet(workbook, ws, `Sales Gift Details`);

    // Uncomment the line below if you want to save the file directly within the function
    // XLSX.writeFile(workbook, `Sales Gift Details_${offer_name}.xlsx`);
  };

  return (
    <>
      <CustomLoader open={isLoadingTable} />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb" sx={{ padding: "8px 0" }}>
            <MuiLink
              underline="hover"
              component={RouterLink}
              to="/sales-offer"
              color="inherit"
            >
              Sales Offer
            </MuiLink>
            <MuiLink
              underline="hover"
              component={RouterLink}
              to={
                selectedFilter !== "Custom Range" && selectedFilter !== `${customDateRange.start_date} to ${customDateRange.end_date}`
                  ? `/sales-offer/${offer_name}/${id}?time-frame=${selectedFilter}`
                  : `/sales-offer/${offer_name}/${id}?start_date=${customDateRange.start_date}&end_date=${customDateRange.end_date}`
              }
              color="inherit"
            >
              {offer_name}
            </MuiLink>
            <MuiLink
              underline="hover"
              component={RouterLink}
              to={
                selectedFilter !== "Custom Range" && selectedFilter !== `${customDateRange.start_date} to ${customDateRange.end_date}`
                  ? `/sales-offer/${offer_name}/${id}/${salesgroup}/?time-frame=${selectedFilter}`
                  : `/sales-offer/${offer_name}/${id}/${salesgroup}/?start_date=${customDateRange.start_date}&end_date=${customDateRange.end_date}`
              }
              color="inherit"
            >
              {salesgroup}
            </MuiLink>
            <MuiLink
              underline="hover"
              component={RouterLink}
              to={
                selectedFilter !== "Custom Range" && selectedFilter !== `${customDateRange.start_date} to ${customDateRange.end_date}`
                  ? `/sales-offer/${offer_name}/${id}/${salesgroup}/${payerId}/?time-frame=${selectedFilter}`
                  : `/sales-offer/${offer_name}/${id}/${salesgroup}/${payerId}/?start_date=${customDateRange.start_date}&end_date=${customDateRange.end_date}`
              }
              color="inherit"
            >
              {payerId}
            </MuiLink>
            <Typography color="textPrimary" sx={{ padding: "0 4px" }}>
              {outledId}
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          spacing={2}
          alignItems="center"
          marginBottom={3}
        >
          {isLoadingTable || isLoadingGift ? (
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: "#5052ba",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#4042a3",
                  },
                  p: 1,
                  ml: 2,
                }}
                startIcon={<SaveAltIcon />}
                disabled
              >
                Loading...
              </Button>
            </Grid>
          ) : (
            <Grid item>
              <Button
                size="small"
                variant="contained"
                onClick={exportExcel}
                sx={{
                  backgroundColor: "#5052ba",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#4042a3",
                  },
                  ml: 2,
                  p: 1,
                }}
                startIcon={<SaveAltIcon />}
              >
                Export Excel
              </Button>
            </Grid>
          )}
          {/* <Grid item>
            <FormControl className={search} fullWidth>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search"
                sx={{ bgcolor: "#fff", width: 300 }}
                value={searchValue}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchValue ? (
                        <ClearIcon
                          className={clearIcon}
                          onClick={handleClearSearch}
                        />
                      ) : (
                        <SearchIcon style={{ display: setShowClearIcon }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid> */}

          <Grid item>
            <Tooltip
              title={
                selectedFilter === "Today"
                  ? customDateRange.start_date
                  : `${customDateRange.start_date} ${customDateRange.end_date}`
              }
              placement="top"
            >
              <Autocomplete
                disablePortal
                size="small"
                sx={{ bgcolor: "#fff", width: 200 }}
                id="combo-box-demo"
                value={selectedFilter}
                options={[
                  "Today",
                  "Weekly",
                  "Monthly",
                  "Yearly",
                  "Custom Range",
                ]}
                getOptionLabel={(option) => option}
                onChange={(event, value) => updateAutoComplete(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Date Filter" />
                )}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <TableWrapper>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "450px", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell isHeader>Gift Name</StyledTableCell>
                {couponNoExists && (
                  <StyledTableCell isHeader>Coupon No</StyledTableCell>
                )}
                <StyledTableCell isHeader>Outlet Id</StyledTableCell>
                {uniqueCouponTypes.map((type, index) => (
                  <StyledTableCell key={index} isHeader>
                    {type}
                  </StyledTableCell>
                ))}
                <StyledTableCell isHeader>Total Count</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {filteredSalesDetails.map((detail, index) => (
                <StyledTableRow key={index}>
                  <TableCell align="center">{detail.gift_name}</TableCell>
                  {couponNoExists && (
                    <TableCell align="center">{detail.coupon_no}</TableCell>
                  )}
                  <TableCell align="center">{detail.outletId}</TableCell>
                  {uniqueCouponTypes.map((type, index) => (
                    <TableCell align="center" key={index}>
                      {detail.coupon_type.includes(type) ? 1 : 0}
                    </TableCell>
                  ))}
                  <TableCell align="center">{1}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <StyledTableCell isFooter>Total</StyledTableCell>
                {couponNoExists && <StyledTableCell isFooter />}
                <StyledTableCell isFooter />
                {uniqueCouponTypes.map((type, index) => (
                  <StyledTableCell isFooter key={index}>
                    {Object.values(aggregatedSalesDetails).reduce(
                      (sum, detail) => sum + (detail.coupon_types[type] || 0),
                      0
                    )}
                  </StyledTableCell>
                ))}
                <StyledTableCell isFooter>{overallTotalCount}</StyledTableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </TableWrapper>

      <CustomDialog
        open={openDialog}
        onClose={handleDialogClose}
        title="Select Date Range"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TextField
            label="Start Date"
            type="date"
            value={customDateRange.start_date}
            onChange={handleDateChange("start_date")}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            type="date"
            value={customDateRange.end_date}
            onChange={handleDateChange("end_date")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            onClick={handleCustomRangeSubmit}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Box>
      </CustomDialog>
    </>
  );
};

export default SalesOfferByOutletIdDetail;
