import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import Chart from "react-apexcharts";
import { getRequestInstanceOne } from "../../../apiServices/apiCall";

const StateOrderChart = ({
  selectedSalesgroup,
  selectedStockist,
  selectedNames,
  customDateRange,
}) => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%", // Default width
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
      },
    },
    // dataLabels: {
    //     enabled: true,
    //     formatter: function (val) {
    //         if (val >= 1000) {
    //             return (val / 1000).toFixed(1) + 'K';
    //         }
    //         return val;
    //     }
    // },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        // Get the total sum of the stack
        const seriesIndex = opts.seriesIndex;
        const dataPointIndex = opts.dataPointIndex;
        const series = opts.w.config.series;
        const total = series.reduce(
          (acc, seriesItem) => acc + seriesItem.data[dataPointIndex],
          0
        );
        // Display the total only on the last series item
        if (seriesIndex === series.length - 1) {
          return total ? total : "";
        } else {
          return "";
        }
      },
      offsetY: -20,
      style: {
        colors: ["#000"], // Customize the color of the total labels
      },
    },
    colors: ["#1E3A8A", "#D97706"],
    xaxis: {
      categories: [],
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Arial, sans-serif",
        },
      },
    },
    fill: {
      colors: ["#1E3A8A", "#D97706"],
    },
    series: [
      {
        name: "Order Count",
        data: [],
      },
      {
        name: "No Order Count",
        data: [],
      },
    ],
  });

  const [totals, setTotals] = useState({ totalOrders: 0, totalNoOrders: 0 });

  const fetchData = useCallback(async () => {
    try {
      let params;
      // Construct the URL parameters

      if (localStorage.getItem("user") != "admin") {
        params = new URLSearchParams({
          ...(customDateRange && { start_date: customDateRange.start_date }),
          ...(customDateRange && { end_date: customDateRange.end_date }),
          ...(selectedSalesgroup && { salesgroup: selectedSalesgroup }),
          ...{ user: localStorage.getItem("user") },
          ...(selectedNames && { name: selectedNames }),
        });
      } else {
        params = new URLSearchParams({
          ...(customDateRange && { start_date: customDateRange.start_date }),
          ...(customDateRange && { end_date: customDateRange.end_date }),
          ...(selectedSalesgroup && { salesgroup: selectedSalesgroup }),
          ...(selectedStockist && { stockist: selectedStockist }),
          ...(selectedNames && { name: selectedNames }),
        });
      }

      const url = `/api/salesby_group?${params.toString()}`;

      // Fetch data from the API
      const response = await getRequestInstanceOne(url);

      // Log the response for debugging purposes
      console.log("response salesby group ===>", response);

      // Check if the response is valid
      if (response?.data && response.code === 200) {
        const categories = response.data.map((item) => item._id);
        const orderCounts = response.data.map((item) => item.orderCount ?? 0); // Handle null values
        const noOrderCounts = response.data.map(
          (item) => item.noOrderCount ?? 0
        ); // Handle null values

        const totalOrders = orderCounts.reduce((a, b) => a + b, 0);
        const totalNoOrders = noOrderCounts.reduce((a, b) => a + b, 0);

        setTotals({ totalOrders, totalNoOrders });

        // Update the chart options state
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            categories,
          },
          series: [
            {
              name: "Order Count",
              data: orderCounts,
            },
            {
              name: "No Order Count",
              data: noOrderCounts,
            },
          ],
          plotOptions: {
            ...prevOptions.plotOptions,
            bar: {
              ...prevOptions.plotOptions.bar,
              columnWidth: orderCounts.length > 1 ? "60%" : "40%", // Adjust width based on data length
            },
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [selectedSalesgroup, selectedStockist, selectedNames, customDateRange]);

  useEffect(() => {
    fetchData();

    // // Set up polling to fetch data every minute
    // const intervalId = setInterval(() => {
    //     fetchData();
    // }, 60000); // 60 seconds

    // // Clean up the interval on component unmount
    // return () => clearInterval(intervalId);
  }, [customDateRange, fetchData]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Sales By Group</Typography>
          <Typography align="center">
            {selectedSalesgroup} - {selectedStockist} - {selectedNames}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography
            variant="subtitle2"
            sx={{
              backgroundColor: "#5052ba",
              padding: "8px",
              mr: 2,
              borderRadius: "4px",
              color: "wheat",
            }}
          >
            Total Orders: {totals.totalOrders}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              backgroundColor: "#d97706d9",
              padding: "8px",
              borderRadius: "4px",
              color: "white",
            }}
          >
            Total No Orders: {totals.totalNoOrders}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {chartOptions.series[0].data.length > 0 ? (
          <Chart
            options={chartOptions}
            series={chartOptions.series}
            type="bar"
            height={chartOptions.chart.height}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={chartOptions.chart.height}
          >
            <Typography variant="h6">No data available</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default StateOrderChart;
