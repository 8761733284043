// routes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import GroupByOrderDetails from "./pages/GroupBy/GroupByOrderDetails";
import GroupByData from "./pages/GroupBy/GroupByData/GroupByData";
import SaleOffer from "./pages/SalesOffers/SaleOffer";
import SalesOfferDetail from "./pages/SalesOffers/SalesOfferDetail"; // Import SalesOfferDetail component
import OrderDetails from "./pages/Dashboard/OrderDetails/OrderDetails";
import SalesOfferByPayer from "./pages/SalesOffers/SalesOfferByPayer";
import SalesOfferByPayerDetails from "./pages/SalesOffers/SalesOfferByPayerDetails";
import SalesOfferByOutletIdDetail from "./pages/SalesOffers/SalesOfferByOutletIdDetail";
import Attendance from "./pages/Attendance/Attendance";
import Gift from "./pages/Gift/Gift";

const AppRoutes = () => {
  const isAdmin = localStorage.getItem("user") === "admin";  
  const userRole = localStorage.getItem("user");

  return isAdmin ? (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/Categories" element={<GroupByOrderDetails />} />
      <Route path="/all-orders" element={<OrderDetails />} />
      <Route path="/sales-offer" element={<SaleOffer />} />
      <Route path="/attendance" element={<Attendance />} />
      <Route path="/gift" element={<Gift />} />
      <Route
        path="/sales-offer/:offer_name/:id"
        element={<SalesOfferDetail />}
      />{" "}
      <Route
        path="/sales-offer/:offer_name/:id/:salesgroup/"
        element={<SalesOfferByPayer />}
      />
      <Route
        path="/sales-offer/:offer_name/:id/:salesgroup/:payerId/"
        element={<SalesOfferByPayerDetails />}
      />
      <Route
        path="/sales-offer/:offer_name/:id/:salesgroup/:payerId/:outledId/"
        element={<SalesOfferByOutletIdDetail />}
      />
      <Route path="/group-by/data" element={<GroupByData />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/all-orders" element={<OrderDetails />} />
      <Route path="/sales-offer" element={<SaleOffer />} />
      <Route
        path="/sales-offer/:offer_name/:id"
        element={<SalesOfferDetail />}
      />{" "}
      <Route
        path="/sales-offer/:offer_name/:id/:salesgroup/"
        element={<SalesOfferByPayer />}
      />
      <Route
        path="/sales-offer/:offer_name/:id/:salesgroup/:payerId/"
        element={<SalesOfferByPayerDetails />}
      />
      <Route
        path="/sales-offer/:offer_name/:id/:salesgroup/:payerId/:outledId/"
        element={<SalesOfferByOutletIdDetail />}
      />
    </Routes>
  );
};

export default AppRoutes;
