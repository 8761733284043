import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as XLSX from "xlsx";

// Styling for the table cells and rows
const StyledTableCell = styled(TableCell)(({ theme, isHeader, isFooter }) => ({
  backgroundColor: isHeader ? "#d3d4ee" : isFooter ? "#f5f5f5" : "transparent",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: "center",
  position: isHeader || isFooter ? "sticky" : "relative",
  top: isHeader ? 0 : "auto",
  bottom: isFooter ? 0 : "auto",
  zIndex: isHeader || isFooter ? 1 : "auto",
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
  padding: "8px 12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "6px 10px",
  },
}));

const TableWrapper = styled(Box)({
  width: "100%",
  height: "100%",
  overflowX: "auto",
});

const Gift = () => {
  const [giftData, setGiftData] = useState([]); // State to store the API data
  const [openDialog, setOpenDialog] = useState(false); // State to control dialog visibility
  const [selectedImages, setSelectedImages] = useState([]); // State to store selected images for viewing
  const [filter, setFilter] = useState("Today"); // State for the date filter dropdown
  const [startDate, setStartDate] = useState(""); // Custom start date for Date Range
  const [endDate, setEndDate] = useState(""); // Custom end date for Date Range
  const [exportDialogOpen, setExportDialogOpen] = useState(false); // State to control export dialog visibility

  // Fetch data from the API on page load
  useEffect(() => {
    const fetchGiftApiData = async () => {
      // const startDate = "2024-09-19";
      // const endDate = "2024-09-19";
      // const url = `http://192.168.0.26:8080/api/gift_api?start_date=${startDate}&end_date=${endDate}`;
      const url = `http://143.244.136.36:8084/api/gift_api?`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const result = await response.json();
        setGiftData(result.data); // Store the 'data' array from the response
      } catch (error) {
        console.error("Error fetching the API:", error);
      }
    };

    fetchGiftApiData();
  }, [filter, startDate, endDate]); // Refetch when filter or dates change

  // Function to open the dialog with selected images
  const handleViewImages = (images) => {
    setSelectedImages(images);
    setOpenDialog(true);
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImages([]);
  };

  // Function to open the export dialog
  const handleOpenExportDialog = () => {
    setExportDialogOpen(true);
  };

  // Function to close the export dialog
  const handleCloseExportDialog = () => {
    setExportDialogOpen(false);
  };

  // Function to handle exporting to Excel
  const handleExportExcel = () => {
    const formattedData = giftData.map((row) => ({
      Salesgroup: row.r_info.salesgroup,
      "Payer Id": row.r_info.payerId,
      Name: row.gift_by.name,
      Date: row.date,
      "Offer Id": row.offerId,
      "Offer Name": row.offer_name,
      "Outlet Id": row.outletId,
      Remark: row.remark,
      Image: row.img.join(", "), // In case of multiple images, join URLs into a string
    }));

    // Convert the formatted data to an Excel sheet
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Gift Data");

    // Download the Excel file
    XLSX.writeFile(wb, "GiftData.xlsx");
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Date Filter Dropdown */}
        {/* <Grid item>
          <FormControl sx={{ minWidth: 200, height: 40, marginBottom: 2 }}>
            <InputLabel>Date Filter</InputLabel>
            <Select
              value={filter}
              label="Date Filter"
              onChange={(e) => setFilter(e.target.value)}
              sx={{ height: 40 }} // Set height for the Select component
            >
              <MenuItem value="Today">Today</MenuItem>
              <MenuItem value="Weekly">Weekly</MenuItem>
              <MenuItem value="Monthly">Monthly</MenuItem>
              <MenuItem value="Yearly">Yearly</MenuItem>
              <MenuItem value="DateRange">Date Range</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        {/* Date Range Picker */}
        {filter === "DateRange" && (
          <Grid item>
            <Box display="flex" gap={2}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Grid>
        )}

        <Grid item>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
            Gift Table
          </Typography>
        </Grid>

        {/* Export to Excel Button */}
        <Grid item>
          <Button
            variant="contained"
            sx={{ background: "#5052BA", color: "white", marginBottom: "15px" }}
            onClick={handleOpenExportDialog}
          >
            Export Excel
          </Button>
        </Grid>
      </Grid>

      <TableWrapper>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "450px", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell isHeader>Salesgroup</StyledTableCell>
                <StyledTableCell isHeader>Payer Id</StyledTableCell>
                <StyledTableCell isHeader>Name</StyledTableCell>
                <StyledTableCell isHeader>Date</StyledTableCell>
                <StyledTableCell isHeader>Offer Id</StyledTableCell>
                <StyledTableCell isHeader>Offer Name</StyledTableCell>
                <StyledTableCell isHeader>Outlet Id</StyledTableCell>
                <StyledTableCell isHeader>Remark</StyledTableCell>
                <StyledTableCell isHeader>Image</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {giftData.length > 0 ? (
                giftData.map((row, index) => (
                  <StyledTableRow key={index}>
                    <TableCell align="center">
                      {row.r_info.salesgroup}
                    </TableCell>
                    <TableCell align="center">{row.r_info.payerId}</TableCell>
                    <TableCell align="center">{row.gift_by.name}</TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">{row.offerId}</TableCell>
                    <TableCell align="center">{row.offer_name}</TableCell>

                    <TableCell align="center">{row.outletId}</TableCell>
                    <TableCell align="center">{row.remark}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        size="small"
                        color="success"
                        onClick={() => handleViewImages(row.img)}
                      >
                        View Image
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={8} align="center">
                    No data available
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>

      {/* Dialog to show images */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ background: "#727297", color: "white" }}>
          Images
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: "500px" }}>
          <Stack direction="column" spacing={2}>
            {selectedImages.map((imgUrl, index) => (
              <img
                key={index}
                src={imgUrl}
                alt={`Gift Image ${index + 1}`}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "400px",
                  objectFit: "cover",
                }}
              />
            ))}
          </Stack>
        </DialogContent>
      </Dialog>

      {/* Export Dialog Box */}
      <Dialog
        open={exportDialogOpen}
        onClose={handleCloseExportDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ background: "#727297", color: "white" }}>
          Export Data
          <IconButton
            aria-label="close"
            onClick={handleCloseExportDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ maxHeight: "500px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell isHeader>Salesgroup</StyledTableCell>
                <StyledTableCell isHeader>Payer Id</StyledTableCell>
                <StyledTableCell isHeader>Name</StyledTableCell>
                <StyledTableCell isHeader>Date</StyledTableCell>
                <StyledTableCell isHeader>Offer Id</StyledTableCell>
                <StyledTableCell isHeader>Offer Name</StyledTableCell>
                <StyledTableCell isHeader>Outlet Id</StyledTableCell>
                <StyledTableCell isHeader>Remark</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {giftData.length > 0 ? (
                giftData.map((row, index) => (
                  <StyledTableRow key={index}>
                    <TableCell align="center">
                      {row.r_info.salesgroup}
                    </TableCell>
                    <TableCell align="center">{row.r_info.payerId}</TableCell>
                    <TableCell align="center">{row.gift_by.name}</TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">{row.offerId}</TableCell>
                    <TableCell align="center">{row.offer_name}</TableCell>
                    <TableCell align="center">{row.outletId}</TableCell>
                    <TableCell align="center">{row.remark}</TableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={7} align="center">
                    No data available
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <Box display="flex" justifyContent="center" p={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportExcel}
          >
            Download Excel
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Gift;
